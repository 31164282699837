/**
 * 레이아웃을 관리하는 store
 */

export default defineStore(
  'layoutStore',
  () => {
    // Aside 메뉴 보이기/숨기기
    const asdieCollapsed = ref(false);

    // RTL mode
    const asideRtlMode = ref(false);

    return {
      asdieCollapsed,
      asideRtlMode,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
      serializer: {
        serialize: JSON.stringify,
        deserialize: JSON.parse,
      },
    },
  },
);
