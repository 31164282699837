// 메뉴트리를 위한 메뉴데이터
export const useMenuList = () => {
  const { menuData } = storeToRefs(useMenuStore());

  const menu = computed(() => {
    if (!menuData.value || menuData.value.length < 1) {
      return [];
    }

    return menuData.value.map(transformMenuItem);
  });

  // Recursive function to transform menu items
  function transformMenuItem({ id, title, type, scrnSizeId, icon, multiYn, sizeFixedYn, isScrnAccess, child, apndFileId }) {
    const menu = {
      id: id,
      title: title,
      type: type,
      scrnSizeId: scrnSizeId,
      multiYn: multiYn,
      sizeFixedYn: sizeFixedYn,
      disabled: type === 'menu' ? false : isScrnAccess === 'true' ? false : true,
      apndFileId: apndFileId,
    };

    if (icon) {
      menu.icon = {
        element: 'AppAsideIcon',
        attributes: {
          name: icon,
          tooltip: title,
        },
      };
    }

    if (child?.length > 0) {
      menu.child = child.map(transformMenuItem);
    }

    return menu;
  }

  return menu;
};

// 실제화면정보를 가지고 있는 메뉴목록
export const useScreenList = () => {
  const menu = useMenuList();

  // screen menu with flatten
  return computed(() => {
    const screenMenu = [];
    const reducer = (items) => {
      if (!items || items.length === 0) {
        return;
      }
      items.forEach((item) => {
        if (item.type === 'screen') {
          screenMenu.push(item);
        } else if (item.child?.length !== 0) {
          reducer(item.child);
        }
      });
    };
    reducer(menu.value || []);
    return screenMenu;
  });
};
