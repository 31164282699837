<template>
  <div class="flex size-full flex-col items-center justify-center gap-4 text-4xl">
    <h1>{{ companyId }}</h1>
    <h2>{{ error.statusCode }}</h2>
    <Button @click="handleError">되돌아가기</Button>
  </div>
  <Toast group="system" />
</template>

<script setup>
const props = defineProps({
  error: Object,
});

const { companyId } = useAppConfig();
const error = toRef(props, 'error');

const handleError = () => clearError({ redirect: `/${companyId}/` });
</script>
