// 시스템용 toast 서비스
export const useSystemToast = () => {
  // inject 문제로 global로 설정된 toast 객체 사용
  const toast = useNuxtApp().vueApp.config.globalProperties.$toast;

  // 기본 사라짐 옵션 제거
  const defaultLife = 3_000;

  const SYSTEM_GROUP = '_SYSTEM_';

  return {
    info(detail, summary = '[정보]') {
      toast.add({
        group: SYSTEM_GROUP,
        severity: 'info',
        summary,
        detail,
        life: defaultLife,
      });
    },
    warn(detail, summary = '[경고]') {
      toast.add({
        group: SYSTEM_GROUP,
        severity: 'warn',
        summary,
        detail,
        life: defaultLife,
      });
    },
    error(detail, summary = '[오류]') {
      toast.add({
        group: SYSTEM_GROUP,
        severity: 'error',
        summary,
        detail,
        life: defaultLife,
      });
    },
  };
};
globalThis.useSystemToast = useSystemToast;
