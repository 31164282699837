
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/ko'
import 'dayjs/locale/en'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import objectSupport from 'dayjs/plugin/objectSupport'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(isoWeek)
dayjs.extend(objectSupport)
dayjs.extend(quarterOfYear)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)
dayjs.extend(relativeTime)
dayjs.extend(utc)

dayjs.tz.setDefault('Asia/Seoul')

// defaultLocale: "ko"


dayjs.updateLocale("ko")
dayjs.locale('ko')


export default dayjs
