import revive_payload_client_rtwwQfVA6t from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TibebGSHZL from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_A2Pxw6kAR6 from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MjRdFVdrtH from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RAF7mU5KXQ from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eI2DDZWeAC from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_b7JkDhMLO6 from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_gVVlEmJ5Ol from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/.nuxt/components.plugin.mjs";
import prefetch_client_hJaneLcRKg from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__7l4tp2fwdceo7o2n6cseubkyay/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/.nuxt/primevue-plugin.mjs";
import plugin_client_p0yUDqD57K from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/nuxt-primevue@3.0.0_magicast@0.3.5_rollup@4.21.2_vue@3.4.38_typescript@5.5.4_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_bb3FJ1NUVA from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.21.2/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_NBlGUJUsYP from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/@nuxt+icon@1.5.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.32.0__vue@3.4.38_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_vfJ9eD2HXz from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typesc_cmanhvbhw7ypacpizobu3ygwsm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _00_setup_plugin_0p05P7BEqy from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/plugins/00.setup.plugin.ts";
import _01_library_plugin_a2jGKfsWEg from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/plugins/01.library.plugin.ts";
import _02_primevue_xHu9xwTT4J from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/plugins/02.primevue.ts";
import _10_auth_plugin_s8oCyVX6wP from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/plugins/10.auth.plugin.ts";
import _99_DevelopmentMode_P3AIKrxW4r from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/plugins/99.DevelopmentMode.js";
export default [
  revive_payload_client_rtwwQfVA6t,
  unhead_TibebGSHZL,
  router_A2Pxw6kAR6,
  payload_client_MjRdFVdrtH,
  navigation_repaint_client_RAF7mU5KXQ,
  check_outdated_build_client_eI2DDZWeAC,
  chunk_reload_client_b7JkDhMLO6,
  plugin_vue3_gVVlEmJ5Ol,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hJaneLcRKg,
  primevue_plugin_egKpok8Auk,
  plugin_client_p0yUDqD57K,
  plugin_bb3FJ1NUVA,
  plugin_NBlGUJUsYP,
  plugin_vfJ9eD2HXz,
  _00_setup_plugin_0p05P7BEqy,
  _01_library_plugin_a2jGKfsWEg,
  _02_primevue_xHu9xwTT4J,
  _10_auth_plugin_s8oCyVX6wP,
  _99_DevelopmentMode_P3AIKrxW4r
]