import * as vueuse from '@vueuse/core';

export default defineNuxtPlugin(() => {
  globalThis.ref = ref;
  globalThis.computed = computed;
  globalThis.reactive = reactive;
  globalThis.readonly = readonly;
  globalThis.watchEffect = watchEffect;
  globalThis.watchPostEffect = watchPostEffect;
  globalThis.watchSyncEffect = watchSyncEffect;
  globalThis.watch = watch;
  globalThis.toRef = toRef;
  globalThis.toRefs = toRefs;
  globalThis.toValue = toValue;
  globalThis.toRaw = toRaw;
  globalThis.unref = unref;
  globalThis.isRef = isRef;
  globalThis.isReactive = isReactive;
  globalThis.triggerRef = triggerRef;
  globalThis.shallowRef = shallowRef;
  globalThis.useRouter = useRouter;
  globalThis.useRoute = useRoute;
  globalThis.navigateTo = navigateTo;

  globalThis.storeToRefs = storeToRefs;

  globalThis.useRuntimeConfig = useRuntimeConfig;
  globalThis.useAppConfig = useAppConfig;
  globalThis.appConfig = useAppConfig();

  globalThis.vueuse = vueuse;

  globalThis.useApi = useApi;
  globalThis.useWindowStore = useWindowStore;
  globalThis.useMenuStore = useMenuStore;
  globalThis.useCodeStore = useCodeStore;
  globalThis.useUserStore = useUserStore;
  globalThis.useLayoutStore = useLayoutStore;

  globalThis.useMenuList = useMenuList;
  globalThis.useScreenList = useScreenList;
});
