export default defineNuxtPlugin(async () => {
  const { name } = useRoute();

  // 바로 접속한경우 404 페이지
  if (name === 'NotFound') {
    return;
  }

  const { isLogin } = useAuth();

  // 최초 접속시 로그인 체크
  await isLogin();
});
