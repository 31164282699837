/**
 * 메뉴 목록을 관리하는 store
 */
export default defineStore('wdszStore', () => {
  const wdszData = ref([]);

  const getWindowDefaultSize = (id) => {
    let pawdszDatanel = null;
    if (id !== undefined && id !== null && id !== '') {
      pawdszDatanel = wdszData.value.find(({ scrnSizeId }) => scrnSizeId === id);
    }

    const defaultSize = {
      initWdthSize: 800,
      initHghtSize: 600,
      minWdthSize: 0,
      minHghtSize: 0,
      maxWdthSize: 2560,
      maxHghtSize: 1440,
    };

    return pawdszDatanel || defaultSize;
  };

  return { wdszData, getWindowDefaultSize };
});
