export default defineNuxtPlugin(() => {
  // Locale 설정
  const primevue = usePrimeVue();
  primevue.config.locale!.firstDayOfWeek = 0;
  primevue.config.locale!.fileSizeTypes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  primevue.config.locale!.dayNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  primevue.config.locale!.dayNamesShort = ['일', '월', '화', '수', '목', '금', '토'];
  primevue.config.locale!.dayNamesMin = ['일', '월', '화', '수', '목', '금', '토'];
  primevue.config.locale!.monthNames = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
  primevue.config.locale!.monthNamesShort = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
  primevue.config.locale!.am = '오전';
  primevue.config.locale!.pm = '오후';
  primevue.config.locale!.today = '오늘';
  primevue.config.locale!.clear = '초기화';
});
