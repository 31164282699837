/**
 * 사용자 인증 관련 처리
 */
export const useAuth = () => {
  const { $companyId } = useNuxtApp();
  const toast = useSystemToast();
  const userStore = useUserStore();
  const wdszStore = useWdszStore();
  const menuStore = useMenuStore();
  const codeStore = useCodeStore();

  const api = useApi();
  const router = useRouter();
  /**
   * 로그인 처리
   *
   * @param {Object.<string, string, string, string, string, string>} params 로그인정보
   * @param {string} coId 법인 ID
   * @param {string} membId 회원 ID
   * @param {string} userId 사용자 ID
   * @param {string} pswd 사용자 패스워드
   * @param {string} userIdSaveYn 사용자 ID 저장 여부
   * @param {string} autoLoginYn 자동 로그인 여부
   */
  const login = async ({ coId, membId, userId, pswd, userIdSaveYn, autoLoginYn }) => {
    const { cuuser } = await api('/api/main/login.do', {
      cuuser: { coId, membId, userId, pswd, userIdSaveYn, autoLoginYn },
    });

    if (cuuser.loginErrCd !== '00') {
      toast.error(cuuser.loginErrMsg, '로그인 실패');
      return false;
    } else {
      // userStore.user = cuuser;
      userStore.auth = true;
      const token = getUserToken();
      token.isUserIdSave = userIdSaveYn === 'Y';
      token.userUid = token.isUserIdSave ? cuuser.userUid : null;
      token.isAutoLogin = token.autoLoginYn === 'Y';
      setUserToken(token);
      await setBaseInfo();
      return true;
    }
  };

  const invalidate = async () => {
    userStore.user = null;
    userStore.auth = false;
    saveUserInfo(false);
  };

  /**
   * 로그아웃 처리
   */
  const logout = async () => {
    invalidate();
    await api('/api/main/logout.do');

    // 로그아웃시 pinia store 클리어를 위해서 새로고침
    //defineNuxtRouteMiddleware 에서 auth 체크 login 페이지로 이동
    // await navigateTo({ name: 'Login' });
    router.go(0);
  };

  /**
   * 서버에서 로그인 여부를 확인하며 로그인이 된 경우 만약 사용자 정보가 없다면
   * 사용자정보를 서버에서 요청받아 userStore에 설정
   *
   * @returns {Promise<boolean>} 로그인 여부
   */
  const isLogin = async () => {
    const { companyId } = useAppConfig();
    const token = getUserToken();
    if (token && token.membUid !== companyId) {
      token.membUid = companyId;
      setUserToken(token);
      await logout();
      return false;
    }

    const { isLogin } = await api('/api/main/isLogin.do');
    if (!isLogin) {
      userStore.user = null;
      userStore.auth = false;
      return false;
    }

    if (!userStore.auth) {
      userStore.auth = true;
      await setBaseInfo();
    }
    return isLogin;
  };

  const setBaseInfo = async () => {
    const { userInfo, wdszList, menuList, codeList, membScrnMppgList } = await api('/api/main/selectUserSetting.do');
    userStore.user = userInfo;
    wdszStore.wdszData = wdszList;
    menuStore.menuData = menuList;
    codeStore.codeData = { ...codeList, ...membScrnMppgList };
  };

  /**
   * 사용자 고유ID로 로그인 관련 정보를 조회
   */
  const checkUid = async () => {
    const { companyId } = useAppConfig();
    const token = getUserToken() ? getUserToken() : { autoLoginYn: 'N' };
    if (token && token.membUid !== companyId) {
      token.autoLoginYn = 'N';
      token.membUid = companyId;
      for (const key in token) {
        if (!['autoLoginYn', 'membUid'].includes(key)) delete token[key];
      }
      setUserToken(token);
      await logout();
    }
    const url = `/api/main/checkUid.do`;
    const { userInfo } = await api(url, { uidVo: token });

    if (userInfo.uidErrCd !== '00') {
      toast.error(userInfo.uidErrMsg, '고유번호 확인 실패');
      return false;
    } else {
      setUserToken({ membUid: userInfo.membUid, userUid: userInfo.userIdSaveYn === 'Y' ? userInfo.userUid : null, isAutoLogin: token.autoLoginYn === 'Y' });
      userStore.userUid = userInfo;
    }
  };

  /**
   * 사용자 정보를 저장소에 저장
   * @param {Object} data 사용자 정보
   */
  const saveUserInfo = (data) => {
    const token = getUserToken();
    token.isAutoLogin = data;
    setUserToken(token);
  };

  /**
   * 저장소에 저장되어 있는 사용자 정보 조회
   * @returns {Object} 사용자 정보
   */
  const loadUserInfo = () => {
    return getUserToken().autoLoginYn;
  };

  const token_key = 'token';

  /**
   * 토큰스토어에 저장되어 있는 사용자 토큰 정보 가져오기
   * @returns {Object} 사용자 토큰 정보
   */
  const getUserToken = () => {
    const { companyId } = useAppConfig();
    const token = getTokenWithExpiry(token_key);
    if (!token) return { membUid: companyId, autoLoginYn: 'N' };
    return JSON.parse(decodeURIComponent(atob(token)));
  };

  /**
   * 사용자 토큰 정보를 토큰스토어에 저장
   * @param {Object} data 사용자 정보
   */
  const setUserToken = (data) => {
    const { companyId } = useAppConfig();
    const saveToken = { autoLoginYn: data.isAutoLogin ? 'Y' : 'N', membUid: data.membUid ? data.membUid : companyId, userUid: data.userUid };
    const encryptToken = btoa(encodeURIComponent(JSON.stringify(saveToken)));
    setTokenWithExpiry(token_key, encryptToken, 30, PERIOD.DATE);
  };

  /**
   * 기간 enum
   */
  const PERIOD = {
    /** 년단위 */ YEAR: 'y',
    /** 월단위 */ MONTH: 'm',
    /** 일단위 */ DATE: 'd',
    /** 시간단위 */ HOUR: 'h',
    /** 분단위 */ MINUTE: 'M',
    /** 초단위 */ SECOND: 's',
  };

  /**
   * 토큰 정보를 저장
   * @param {String} key 스토리지에 저장할 key
   * @param {String} value 스토리지에 저장할 내용
   * @param {Number} expiryPeriod 스토리지에 저장할 기간
   * @param {String} unit 스토리지에 저장할 기간 단위
   */
  const setTokenWithExpiry = (key, value, expiryPeriod, unit = PERIOD.DATE) => {
    // 만료시간을 설정
    const now = new Date();
    const expiry = new Date(now.getTime());
    if (unit === PERIOD.SECOND) expiry.setSeconds(now.getSeconds() + expiryPeriod);
    else if (unit === PERIOD.MINUTE) expiry.setMinutes(now.getMinutes() + expiryPeriod);
    else if (unit === PERIOD.HOUR) expiry.setHours(now.getHours() + expiryPeriod);
    else if (unit === PERIOD.DATE) expiry.setDate(now.getDate() + expiryPeriod);
    else if (unit === PERIOD.MONTH) expiry.setMonth(now.getMonth() + expiryPeriod);
    else if (unit === PERIOD.YEAR) expiry.setFullYear(now.getFullYear() + expiryPeriod);
    const token = { value, expiry: expiry.getTime() };
    // token을 암호화처리
    const encryptToken = btoa(encodeURIComponent(JSON.stringify(token)));
    // token을 localStorage에 저장
    localStorage.setItem(key, encryptToken);
  };

  /**
   * 토큰 정보를 조회
   * @param {String} key 스토리지에 저장되어 있는 key
   * @returns
   */
  const getTokenWithExpiry = (key) => {
    const tokenStr = localStorage.getItem(key);
    // 해당키로 저장된 데이터가 없으면 null 반환
    if (!tokenStr) return null;
    const token = JSON.parse(decodeURIComponent(atob(tokenStr)));
    const now = Date.now();
    // 현재시간과 만료시간을 비교
    if (now > token.expiry) {
      // 데이터가 만료되었으면 localStorage에서 해당 데이터를 삭제하고 null 반환
      localStorage.removeItem(key);
      return null;
    }
    // 데이터가 아직 유효하면 값을 반환
    return token.value;
  };

  return {
    login,
    logout,
    isLogin,
    invalidate,
    checkUid,
    saveUserInfo,
    loadUserInfo,
    setTokenWithExpiry,
    getTokenWithExpiry,
    PERIOD,
  };
};
