export default defineNuxtPlugin(() => {
  // 여기서 회사정보 설정
  const segments = location.pathname.split('/').filter((segment) => segment !== '');
  if (segments.length === 0) {
    return;
  }
  const companyId = segments[0];
  const config = useAppConfig();
  config.companyId = companyId;

  return {
    provide: {
      companyId,
    },
  };
});
