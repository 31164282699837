/**
 * AgGrid module plugin init
 */
// community modules
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridVue } from '@ag-grid-community/vue3';

// enterprise modules
// import { LicenseManager } from '@ag-grid-enterprise/core';
// import { GridChartsModule } from '@ag-grid-enterprise/charts-enterprise';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
// import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
// import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
// import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
// import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
// import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
// import { SideBarModule } from '@ag-grid-enterprise/side-bar';
// import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
// import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { LicenseManager } from '@ag-grid-enterprise/core';

import VueDOMPurifyHTML from 'vue-dompurify-html';

import VueApexChart from 'vue3-apexcharts';

export default defineNuxtPlugin((nuxtApp) => {
  const vueApp = nuxtApp.vueApp;

  const dayjs = useDayjs();
  dayjs.extend((o, Dayjs) => {
    const DEFAULT_FORMAT_EXPR = 'YYYYMMDD';

    const dayjsProto = Dayjs.prototype;
    const oldFormat = dayjsProto.format;

    dayjsProto.format = function (formatStr: string) {
      return oldFormat.call(this, formatStr || DEFAULT_FORMAT_EXPR);
    };
  });

  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    // InfiniteRowModelModule,
    CsvExportModule,
    // GridChartsModule,
    SparklinesModule,
    ClipboardModule,
    // ColumnsToolPanelModule,
    ExcelExportModule,
    // FiltersToolPanelModule,
    MasterDetailModule,
    MenuModule,
    RangeSelectionModule,
    RichSelectModule,
    RowGroupingModule,
    // ServerSideRowModelModule,
    // SetFilterModule,
    // MultiFilterModule,
    // AdvancedFilterModule,
    // SideBarModule,
    // StatusBarModule,
    // ViewportRowModelModule,
  ]);

  LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-053325}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{EGAP}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{EGSOL}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{EGSOL}_need_to_be_licensed___{EGSOL}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_March_2025}____[v3]_[01]_MTc0MjUxNTIwMDAwMA==fe707c37e59c37601af8a7893394aebb',
  );

  vueApp.component('AgGridVue', AgGridVue);
  vueApp.use(VueApexChart);

  // prevent xss attack
  vueApp.use(VueDOMPurifyHTML);
});
