/**
 * 윈도우 패널을 관리하는 store
 */
export default defineStore(
  'windowStore',
  () => {
    const { vueApp } = useNuxtApp();
    const { getWindowDefaultSize } = useWdszStore();
    // 페이지가 없는 경우
    const component$404 = vueApp.component('404');

    // 패널 정보를 관리
    const users = shallowRef([]);
    const panels = shallowRef([]);

    // 고정패널 처리를 위해 정렬 순서를 재처리한 패널 목록
    const visiblePanels = computed(() => {
      const _panels = panels.value;
      return [..._panels.filter(({ pinned }) => !pinned), ..._panels.filter(({ pinned }) => pinned)];
    });

    // 신규 패널 생성
    // TODO: 차후 기본설정(크기,위치)는 메뉴정보가 수립된 후 초기화
    const createPanel = ({ id, title, width, height, scrnSizeId, multiYn, sizeFixedYn, apndFileId }, data = null) => {
      const screenSize = useCloneDeep(getWindowDefaultSize(scrnSizeId));

      const _panels = panels.value;
      const panel = {
        key: Symbol(id),
        id,
        title: `[${id}] ${title}`,
        width: width ? parseInt(width) : screenSize.initWdthSize,
        height: height ? parseInt(height) : screenSize.initHghtSize,
        x: 2,
        y: 2,
        prevWidth: 0,
        prevHeight: 0,
        prevX: 0,
        prevY: 0,
        maximized: false,
        order: panels.value.length,
        visible: true,
        pinned: false,
        initialData: null,
        scrnSizeId: scrnSizeId,
        sizeFixedYn: sizeFixedYn === 'Y' ? true : false,
        apndFileId: apndFileId,
        component: vueApp.component(id) ?? component$404,
      };

      const index = _panels.findIndex((p) => p.id === id);
      const panelId = ref(panel.key);
      if (multiYn === 'N' && index >= 0) {
        panelId.value = _panels.filter((p) => p.id === id).map(({ key }) => key)[0];
        if (!isVisiblePanel(panelId.value)) {
          toggleVisiblePanel(panelId.value);
        }
        bringToTop(panelId.value);
      } else {
        _panels.push(panel);
        panels.value = _panels;
        triggerRef(panels);
      }
      if (data) {
        setPanelInitialData(panelId.value, data);
      }
    };

    const createPanelTemp = (id) => {
      createPanel({ id, title: `[${id}] TEST화면` });
    };

    // 패널의 정보 조회
    const getPanel = (panelId) => {
      panelId = toValue(panelId);
      const panel = reactive(panels.value.find(({ key }) => key === panelId));
      return panel;
    };

    // 패널 닫기
    const closePanel = async (panelId) => {
      if (await execCloseHooks(panelId)) {
        return;
      }

      const _panels = panels.value;
      const index = _panels.findIndex(({ key }) => key === panelId);
      _panels.splice(index, 1)[0];
      panels.value = _panels;
      triggerRef(panels);

      clearCloseHooks(panelId);
    };

    // 전체 패널 닫기
    const closeAllPanel = async () => {
      const _panels = panels.value;
      const keys = _panels.map(({ key }) => key);
      panels.value = [];
      triggerRef(panels);
      for (let key of keys) {
        clearCloseHooks(key);
      }
    };

    // 선택된 패널을 최상단으로 가져오기
    const bringToTop = (panelId) => {
      const _panels = panels.value;
      const index = _panels.findIndex(({ key }) => key === panelId);
      _panels.push(_panels.splice(index, 1)[0]);
      panels.value = _panels;
      triggerRef(panels);
    };

    // 현재 패널의 크기/위치 정보 반응형 처리
    const usePanelProps = (panelId) => {
      panelId = toValue(panelId);
      const panel = reactive(panels.value.find(({ key }) => key === panelId));
      watchDebounced(panel, () => triggerRef(panels), { debounce: 200, maxWait: 1000 });
      return toRefs(panel);
    };

    // 현재 주어진 패널이 보이는지 여부
    const isVisiblePanel = (panelId) => {
      panelId = toValue(panelId);
      return panels.value.find(({ key }) => key === panelId).visible;
    };

    // 패널 보이기/숨기기 처리
    const toggleVisiblePanel = (panelId) => {
      panelId = toValue(panelId);
      const panel = panels.value.find(({ key }) => key === panelId);
      panel.visible = !panel.visible;
      triggerRef(panels);

      return panel.visible;
    };

    // 패널 고정여부
    const usePinnedPanel = (panelId) => {
      panelId = toValue(panelId);
      return computed(() => panels.value.find(({ key }) => key === panelId)?.pinned);
    };

    // 패널고정 설정(타 고정패널은 해제)
    const enablePinnedPanel = (panelId) => {
      panelId = toValue(panelId);
      panels.value.forEach((panel) => {
        if (panel.key === panelId && !panel.pinned) {
          panel.pinned = true;
        } else {
          panel.pinned = false;
        }
      });
      triggerRef(panels);
    };

    // 현재 최상단으로 활성화 되어 있는 패널키
    const useActivePanelId = () => {
      return computed(() => {
        const _panels = panels.value;

        let idx = _panels.length;

        while (idx-- > 0) {
          const { key, visible, pinned } = _panels[idx];

          if (visible && !pinned) {
            return key;
          }
        }
      });
    };

    // 현재 panel 의 크기를 조절
    const updatePanelSize = (panelId, width, height) => {
      panelId = toValue(panelId);
      const panel = reactive(panels.value.find(({ key }) => key === panelId));
      if (panel) {
        panel.width += width ? width : 0;
        panel.height += height ? height : 0;
        triggerRef(panels);
        usePanelProps(panelId);
      }
    };

    // closeHooks
    const closeHookMap = (globalThis.closeHookMap = new Map());

    // 패널의 closeHook 추가
    const closeHook = (panelId, hook) => {
      let hooks = closeHookMap.get(panelId);
      if (!hooks) {
        closeHookMap.set(panelId, (hooks = []));
      }
      hooks.push(hook);
    };

    // 패널 closeHook를 실행하며 hook의 리턴값이 false인경우 창을 닫지 않음
    const execCloseHooks = async (panelId) => {
      let preventClose = false;
      const hooks = closeHookMap.get(panelId) ?? [];
      for (const hook of hooks) {
        try {
          preventClose ||= false === (await hook());
        } catch (e) {
          // ignore hook error
        }
      }

      return preventClose;
    };

    // 패널의 component에 data 보내기
    const setPanelInitialData = (panelId, data) => {
      panelId = toValue(panelId);
      const panel = reactive(panels.value.find(({ key }) => key === panelId));
      panel.initialData = data;
      return toRefs(panel);
    };

    // 패널의 hook을 정리
    const clearCloseHooks = (panelId) => {
      closeHookMap.delete(panelId);
    };
    return {
      users,
      panels,
      visiblePanels,

      createPanel,
      createPanelTemp,
      getPanel,
      closePanel,
      closeAllPanel,

      bringToTop,

      usePanelProps,
      isVisiblePanel,
      toggleVisiblePanel,

      usePinnedPanel,
      enablePinnedPanel,

      useActivePanelId,
      updatePanelSize,
      setPanelInitialData,

      closeHook,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
      serializer: {
        serialize({ users, panels }) {
          const { user } = useUserStore();

          let saveCount = 3;

          panels = panels.map((panel) => {
            // key(Symbol) 및 component 제거
            const { key: _key, component: _component, ...newPanel } = panel;
            return newPanel;
          });

          // user 객체에서 usid만 추출
          const userUsid = user.usid;

          let updatedUsers = useCloneDeep(users);

          const indexToMove = updatedUsers.findIndex((u) => u.usid === userUsid);

          // 최근 로그인한 계정순으로 배열 정리
          if (indexToMove === -1) {
            const userPanels = { usid: userUsid, panels };
            updatedUsers.unshift(userPanels);
          } else if (indexToMove === 0) {
            updatedUsers[0].panels = panels;
          } else {
            const [userToMove] = updatedUsers.splice(indexToMove, 1);
            userToMove.panels = panels;
            updatedUsers.unshift(userToMove);
          }

          // saveCount 만큼만 저장
          if (updatedUsers.length > saveCount) {
            updatedUsers = updatedUsers.slice(0, saveCount);
          }

          const data = {
            users: updatedUsers,
          };

          return JSON.stringify(data);
        },
        deserialize(data) {
          const { vueApp } = useNuxtApp();
          const { user } = useUserStore();
          const screenList = useScreenList();
          const { getWindowDefaultSize } = useWdszStore();

          // screenList에서 id를 추출하여 ids 배열을 만듭니다.
          const screenIds = new Set(screenList.value.map((screen) => screen.id));

          // 페이지가 없는 경우
          const component$404 = vueApp.component('404');

          // JSON 데이터 파싱
          let parsedData = JSON.parse(data);

          // users 배열이 존재하는 경우 처리
          if (parsedData.users && parsedData.users.length > 0) {
            parsedData.users = parsedData.users.map((parsedUser) => {
              if (parsedUser.usid === user.usid) {
                if (parsedUser.panels && parsedUser.panels.length > 0) {
                  parsedUser.panels = parsedUser.panels
                    .filter((panel) => screenIds.has(panel.id))
                    .map((panel) => {
                      panel.key = Symbol(panel.id); // 패널의 고유 키 복원
                      panel.component = vueApp.component(panel.id) || component$404; // 컴포넌트 복원

                      // 간혈적으로 사이즈가 사라지는 현상
                      const screenSize = useCloneDeep(getWindowDefaultSize(panel.scrnSizeId));
                      if (!panel.height || panel.height < 80) {
                        panel.height = screenSize?.initHghtSize || 800;
                      }
                      if (!panel.width || panel.width < 50) {
                        panel.width = screenSize?.initWdthSize || 600;
                      }
                      return panel;
                    });
                  parsedData.panels = parsedUser.panels;
                }
              }
              return parsedUser;
            });
          }

          return parsedData;
        },
      },
    },
  },
);
