import { default as indexQpa1v7s8M9Meta } from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/pages/[id]/index.vue?macro=true";
import { default as login8ZTOkfOqi5Meta } from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/pages/[id]/login.vue?macro=true";
import { default as indexKdboEaufbUMeta } from "/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/pages/index.vue?macro=true";
export default [
  {
    name: "Index",
    path: "/:id()",
    meta: indexQpa1v7s8M9Meta || {},
    component: () => import("/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "Login",
    path: "/:id()/login",
    meta: login8ZTOkfOqi5Meta || {},
    component: () => import("/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/pages/[id]/login.vue").then(m => m.default || m)
  },
  {
    name: "NotFound",
    path: "/",
    meta: indexKdboEaufbUMeta || {},
    component: () => import("/data/jenkins/workspace/FRONT_USER_NH_AMUNDI/pages/index.vue").then(m => m.default || m)
  }
]