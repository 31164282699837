export const useApi = (options = {}) => {
  options = {
    ...options,
    ...{
      method: 'post',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      async onResponseError({ response }) {
        const toast = useSystemToast();
        const { status, url, _data } = response;
        if (status === 401) {
          const { invalidate } = useAuth();
          await invalidate();
          await navigateTo({
            name: 'Login',
            query: {
              status: 401,
            },
          });
          return;
        }

        if (status === 403) {
          // 권한 오류
          const urls = url.split('/');
          toast.info(`[${urls[4]}] 화면에 ${_data.text}`, '권한 오류 발생');
          return;
        }

        if (status === 500) {
          if (_data && _data.error && _data.error !== '') {
            toast.info(`${_data.error}`, '오류 발생');
          } else {
            toast.info('에러발생');
          }
        }
      },
    },
  };

  return async (uri, data, isExcelDownload = false, downloadFilename = 'download.xlsx') => {
    if (data) {
      options.body = JSON.stringify(data);
    } else {
      delete options.body;
    }

    if (isExcelDownload) {
      options.headers['Accept'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      options.responseType = 'blob';
    }

    const response = await $fetch(uri, options);

    if (isExcelDownload) {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = downloadFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }

    return response;
  };
};
