/**
 * 인증과 관련한 middleware
 */
export default defineNuxtRouteMiddleware(async ({ name }) => {
  // NotFound 페이지인경우
  if (name === 'NotFound') {
    return;
  }
  const { companyId } = useAppConfig();
  if (!companyId) {
    return;
  }

  const { auth } = useUserStore();

  // 인증이 되어 있지 않은 상태에서 로그인 외 페이지 접속인 경우
  if (!auth && name !== 'Login') {
    return navigateTo({
      path: `/${companyId}/login`,
      force: true,
      redirectCode: 301,
    });
  }

  // 인증이 완료된 상태에서 메인페이지가 아닌 경우
  if (auth && (!name || name !== 'Index')) {
    return navigateTo({
      path: `/${companyId}/`,
      force: true,
    });
  }
});
